









import Vue from 'vue'
export default Vue.component('SHPrinterError', {
  components: {
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
  },
  props: {
    details: {
      type: Array,
      required: true,
    },
  },
  computed: {
    prefix() {
      return this.$route.params.prefix;
    },
    shoppix() {
      return this.$route.params.shoppix;
    },
  },
})
